import React, { useEffect, useState } from "react"
import { Flex, Text } from "rebass"
import { useThemeUI } from "theme-ui"
import _ from "lodash"

const TeamBlocks = ({ team = [] }) => {
  const { theme } = useThemeUI()
  const [windowInnerWidth, setWindowInnerWidth] = useState(0)

  useEffect(() => {
    setWindowInnerWidth(window.innerWidth)
  }, [])

  const TeamComponentBlock = ({ data, index }) => {
    return (
      <Flex
        width={[1, 1 / 2]}
        flexDirection={index % 2 === 0 ? "row" : "row-reverse"}
      >
        <Flex
          width={[1 / 2, 1 / 2]}
          sx={{
            backgroundImage: `url(${data.picture})`,
            backgroundSize: "cover",
            position: "relative",
            height: [230, 320, 400],
          }}
        >
          <Flex
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
              opacity: 0.3,
            }}
            p={5}
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          ></Flex>
        </Flex>

        <Flex
          width={[1 / 2, 1 / 2]}
          bg="white"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={5}
        >
          <Text
            fontSize={[0, 1]}
            mb={2}
            textAlign="center"
            lineHeight={1.3}
            fontWeight={800}
          >
            {data.name}
          </Text>

          <Text
            fontSize={["10px", 0]}
            mb={2}
            color="dark"
            textAlign="center"
            fontWeight={300}
          >
            {_.toUpper(data.role)}
          </Text>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex
      px={[3, 8]}
      py={8}
      flexWrap="wrap"
      sx={{
        borderLeft: "1px solid #eeeeee",
        borderRight: "1px solid #eeeeee",
      }}
    >
      {_.map(_.chunk(team, 2), (teamMembers, index) => (
        <Flex
          width={1}
          key={`team-row-${index}`}
          flexWrap={["wrap", "no-wrap"]}
        >
          {_.map(teamMembers, (member, i) => (
            <TeamComponentBlock
              data={member}
              index={
                windowInnerWidth > parseInt(theme.breakpoints[0]) ? index : i
              }
            />
          ))}
        </Flex>
      ))}
    </Flex>
  )
}

export default TeamBlocks
